import React, { useState } from "react";
import Sidebar from "../components/sidebar";
import ChatContainer from "../components/chatContainer";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

const Dashboard = () => {
  const [modelName, setModelName] = useState("gpt-4o-mini");

  const handleModelChange = (event) => {
    setModelName(event.target.value);
  };
  return (
    <div className="flex">
      <div className="hidden md:block w-1/5">
        <Sidebar />
      </div>
      <div className="w-full md:w-4/5 mainBody flex">
        <div className="relative flex items-center w-full">
          <div className="md:absolute md:left-1/2 md:transform md:-translate-x-1/2 header text-center md:text-3xl text-xl ml-1 pt-4 pb-4 tracking-wide self-center">
            Criminal Law Bot
          </div>
          <FormControl
            sx={{
              m: 1,
              minWidth: 120,
              marginLeft: "auto",
              marginRight: "16px",
              marginTop: "16px",
            }}
            size="small"
            className="pr-7 pt-8"
          >
            <InputLabel id="demo-select-small-label">Model</InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={modelName}
              label="Model"
              onChange={handleModelChange}
            >
              <MenuItem value={"meta-llama/Llama-3.3-70B-Instruct-Turbo"}>
                Llama-3.3-70B
              </MenuItem>
              <MenuItem value={"meta-llama/Llama-3.2-3B-Instruct-Turbo"}>
                Llama-3.2-3B
              </MenuItem>
              <MenuItem value={"gpt-4o-mini"}>GPT-4o-Mini</MenuItem>
            </Select>
          </FormControl>
        </div>

        <ChatContainer modelName={modelName} />
      </div>
    </div>
  );
};

export default Dashboard;
