import React, { useEffect, useState } from "react";
import ChatBox from "./chatBox";
import WelcomeTextContainer from "./welcomeTextContainer";
import axios from "axios";
import toast from "react-hot-toast";

const ChatContainer = ({ modelName }) => {
  const [input, setInput] = useState("");
  const [isPromptActive, setIsPromptActive] = useState(false);
  const [currentPrompt, setCurrentPrompt] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [chatData, setChatData] = useState([]);
  const [lastUpdatedChatId, setLastUpdatedChatId] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem("userId"));
  const [chatId, setChatId] = useState(null);

  const fetchResponse = async () => {
    try {
      setIsLoading(true);
      setChatData((prevData) => [
        ...prevData,
        {
          prompt: currentPrompt,
          chatId: "",
          responseId: "",
          analysedQuery: "",
          agentReason: "",
          cotReason: "",
          result: "",
          pricing: "",
          timeTaken: {},
        },
      ]);

      const startTime = performance.now();

      const queryAnalyserResponse = await axios.post(
        "https://ai.taxmann.com/api/query_analyser",
        {
          selected_option: "Law_bot",
          user_input: currentPrompt,
          UserID: userId,
          model_used: modelName,
          ...(chatId && { ChatID: chatId }),
        },
        { headers: { "Content-Type": "application/json" } }
      );

      const endTime = performance.now();

      setChatId(queryAnalyserResponse?.data?.ChatID);
      setChatData((prevChat) =>
        prevChat.map((item, index) =>
          index === prevChat.length - 1
            ? {
                ...item,
                chatId: queryAnalyserResponse?.data?.ChatID,
                responseId: queryAnalyserResponse?.data?.ResponseID,
                analysedQuery: queryAnalyserResponse?.data?.["Analysed Query"],
                timeTaken: {
                  ...item.timeTaken,
                  queryAnalyserApi: ((endTime - startTime) / 1000).toFixed(2),
                },
              }
            : item
        )
      );

      const queryDecomposerStartTime = performance.now();
      const queryDecomposerResponse = await axios.post(
        "https://ai.taxmann.com/api/query_decomposer",
        {
          selected_option: "Law_bot",
          user_input: queryAnalyserResponse?.data?.["Analysed Query"],
          UserID: userId,
          ChatID: queryAnalyserResponse?.data?.ChatID,
          ResponseID: queryAnalyserResponse?.data?.ResponseID,
          model_used: modelName,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      const queryDecomposerEndTime = performance.now();

      setChatData((prevChat) =>
        prevChat.map((item, index) => {
          return index === prevChat.length - 1
            ? {
                ...item,
                timeTaken: {
                  ...item.timeTaken,
                  queryDecomposerApi: (
                    (queryDecomposerEndTime - queryDecomposerStartTime) /
                    1000
                  ).toFixed(2),
                },
                agentReason: Array.isArray(
                  JSON.parse(queryDecomposerResponse?.data?.AgentReason.trim())
                )
                  ? JSON.parse(queryDecomposerResponse?.data?.AgentReason)
                      .join("\n")
                      .trim()
                  : queryDecomposerResponse?.data?.AgentReason.trim(),
              }
            : item;
        })
      );

      const chainOfThoughtsStartTime = performance.now();
      const chainOfThoughtsResponse = await axios.post(
        "https://ai.taxmann.com/api/chain_of_thoughts",
        {
          selected_option: "Law_bot",
          user_input: queryAnalyserResponse?.data?.["Analysed Query"],
          AgentReason: queryDecomposerResponse?.data?.AgentReason,
          UserID: userId,
          ChatID: queryDecomposerResponse?.data?.ChatID,
          ResponseID: queryDecomposerResponse?.data?.ResponseID,
          summary_next_seven: queryAnalyserResponse?.data?.summary_next_seven,
          last_three_queries: queryAnalyserResponse?.data?.last_three_queries,
          model_used: modelName,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      const chainOfThoughtsEndTime = performance.now();

      setChatData((prevChat) =>
        prevChat.map((item, index) =>
          index === prevChat.length - 1
            ? {
                ...item,
                cotReason: chainOfThoughtsResponse?.data?.COTReason.trim(),
                timeTaken: {
                  ...item.timeTaken,
                  cotApi: (
                    (chainOfThoughtsEndTime - chainOfThoughtsStartTime) /
                    1000
                  ).toFixed(2),
                },
              }
            : item
        )
      );

      const criminalRagStartTime = performance.now();
      const criminalRagResponse = await axios.post(
        "https://ai.taxmann.com/api/criminal_rag_response",
        {
          selected_option: "Law_bot",
          user_input: currentPrompt,
          AgentReason: chainOfThoughtsResponse?.data?.AgentReason,
          UserID: userId,
          ChatID: chainOfThoughtsResponse?.data?.ChatID,
          COTReason: chainOfThoughtsResponse?.data?.COTReason,
          ResponseID: chainOfThoughtsResponse?.data?.ResponseID,
          model_used: modelName,
        },
        { headers: { "Content-Type": "application/json" } }
      );
      const criminalRagEndTime = performance.now();

      setChatData((prevChat) =>
        prevChat.map((item, index) =>
          index === prevChat.length - 1
            ? {
                ...item,
                result: criminalRagResponse?.data.Response.trim(),
                timeTaken: {
                  ...item.timeTaken,
                  criminalRagApi: (
                    (criminalRagEndTime - criminalRagStartTime) /
                    1000
                  ).toFixed(2),
                },
                pricing: criminalRagResponse?.data.Pricing,
              }
            : item
        )
      );

      setLastUpdatedChatId(criminalRagResponse?.data?.ResponseID);
    } catch (error) {
      console.error("Error fetching response:", error);
      toast.error("Failed to fetch response. Please try again later.");
    } finally {
      setIsPromptActive(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isPromptActive) {
      fetchResponse();
    }
  }, [isPromptActive]);

  const handleSubmitBtn = (e) => {
    e.preventDefault();
    if (input.length > 0) {
      setIsPromptActive(true);
      setCurrentPrompt(input.trim());
      setInput("");
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSubmitBtn(e);
    }
  };
  return (
    <>
      <div className="chatContainerWrapper">
        <div className="chatContainer mt-3">
          {chatData.map((chat, index) => (
            <ChatBox
              key={chat.responseId}
              chat={chat}
              chatId={chat.responseId}
              isLoading={index === chatData.length - 1 ? isLoading : false}
              setInput={setInput}
              isAnimating={chat.id === lastUpdatedChatId}
            />
          ))}
        </div>
        {chatData.length <= 0 && <WelcomeTextContainer />}
      </div>

      <div className="p-4 m-4 rounded-3xl inputContainer">
        <form className="formContainer" onSubmit={handleSubmitBtn}>
          <textarea
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
            className="chat-input border-none w-11/12"
            placeholder="Ask me anything on Criminal Law"
          />
          <button
            type="submit"
            className="formSubmitBtn"
            disabled={isPromptActive}
          >
            <img
              className="h-5 w-6"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAAAXNSR0IArs4c6QAAAh9JREFUaEPtmk2ojVEUhp835SdJVwYSGSgyVSQDBv4ipFuGlIGJiRkGZqbKWIqhGCkSYUKpW0rduhNlIAOSSPJTYvm21q3b6d5r7e/HQGuPzqn9fXs9z3r3OpMj/pOloTnM7CwwJunckGcNCmJmF4DzDnAROCPJhgAaBMTMynsvAadHir4MnJL0q2+Y3kGaLiwArgAngO/AYi96+vM14KSkn33C9AriEFeB48AX4AjwwAveCdwBlgE3gGOSfvQF0xuImS0ErgPjwCfggKSnZvbnTqhcDrMtwD1ghUMdbYZA6VTn1QuImS0CbgKHm3vxEdgvaaJUNxPEv28G7gMrHWpc0reuJJ1BzGwpcAvY3Vzkd8AeSZPThY2COMwm4CGwGngMHJT0uQtMJxAzWw7cBbYDbxxiamZBs4E4zEaHWdOAPAP2SfrQFqY1iJmNeTS2NhPqFbBL0svRQuYCcZh1wCNgPfAc2CvpfRuYLiDlDhSIFyVWkl7PVsB8IA6z1juzoblbE5K2/WuQku0lwCFJb+c6/G8gDrMKuN388n+VVMZ09WrdkehJEZDou+bblyBRi9mRqCnfl9GKCstoRU1ltCpNZbQqheXUigrLaEVN5dSqNJXRqhSWUysqLKMVNZVTq9JURqtSWE6tqLCMVtRUTq1KUxmtSmE5taLCMlpRUzm1Kk1ltCqF5dSKCjOzJ+V/A5J2RJ9ps2/wjrQpqs0zvwHoMR9CZUTGjgAAAABJRU5ErkJggg=="
              alt="up"
            />
          </button>
        </form>
      </div>
    </>
  );
};

export default ChatContainer;
