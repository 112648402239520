import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/logo";
import toast from "react-hot-toast";
import axios from "axios";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    if (username && password) {
      try {
        const loginResponse = await axios.post(
          "https://ai.taxmann.com/api/get_user_id",
          { username: username, password: password },
          { headers: { "Content-Type": "application/json" } }
        );

        loginResponse?.data?.UserID && localStorage.setItem("userId", loginResponse?.data?.UserID);
        toast.success("User logged in successfully!");
        navigate("/dashboard");
      } catch (error) {
        console.error(error);
        toast.error("Invalid credentials.");
      }
    } else {
      toast.error("Please enter username and password.");
    }
  };

  return (
    <div className="h-screen bg-pink-light-100">
      <Logo />
      <div className="flex justify-center items-center ">
        <div className="p-8 rounded-lg shadow-lg w-96 loginContainter">
          <h2 className="text-4xl font-semibold text-center">Login</h2>

          <form className="mt-6" onSubmit={handleLogin}>
            <div className="mb-4">
              <label className="block">
                Enter Username <span className="text-pink-cta">*</span>
              </label>
              <input
                type="text"
                className="w-full mt-1 p-2 border-2 focus:outline-none rounded-2xl h-14"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="mb-4 relative">
              <label className="block">
                Enter Password <span className="text-pink-cta">*</span>
              </label>
              <input
                type={showPassword ? "text" : "password"}
                className="w-full mt-1 p-2 border-2 focus:outline-none rounded-2xl h-14"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <span
                className="absolute top-11 right-3 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <EyeInvisibleOutlined /> : <EyeOutlined />}
              </span>
            </div>

            {/* <div className="mb-4 flex items-center">
            <input type="checkbox" className="mr-2" />
            <label className="text-pink-900">Remember Me</label>
          </div> */}

            <button
              type="submit"
              className="w-full text-white p-2 rounded-md transition flex justify-center items-center bg-pink-cta mt-8"
            >
              Login →
            </button>

            <p className="text-center mt-4">
              Don't have an account?{" "}
              <Link to="/register" className="text-pink-cta font-semibold">
                Create one
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
