import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../components/logo";
import toast from "react-hot-toast";
import axios from "axios";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();

  const handleRegister = async(e) => {
    e.preventDefault();

    if (username && password) {
      try {
        const signupResponse = await axios.post(
          "https://ai.taxmann.com/api/create_user",
          { username: username, password: password },
          { headers: { "Content-Type": "application/json" } }
        );

        signupResponse?.data?.UserID && localStorage.setItem("userId", signupResponse?.data?.UserID);
        toast.success("Acccount created successfully!");
        navigate("/dashboard");
      } catch (error) {
        console.error(error);
        toast.error("Error while creating new account. Try after some time.");
      }
    } else {
      toast.error("Please enter username and password to create account.");
    }
  };

  return (
    <div className="h-screen bg-pink-light-100">
      <Logo />
      <div className="flex justify-center items-center">
        <div className="p-8 rounded-lg shadow-lg w-96 loginContainter">
          <h2 className="text-4xl font-semibold text-center">Create Account</h2>

          <form className="mt-6" onSubmit={handleRegister}>
            <div className="mb-4">
              <label className="block">
                Enter Username <span className="text-pink-cta">*</span>
              </label>
              <input
                type="text"
                className="w-full mt-1 p-2 border-2 focus:outline-none rounded-2xl h-14"
                placeholder="Enter your username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </div>

            <div className="mb-4">
              <label className="block">
                Enter Password <span className="text-pink-cta">*</span>
              </label>
              <input
                type="password"
                className="w-full mt-1 p-2 border-2 focus:outline-none rounded-2xl h-14"
                placeholder="Enter your password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>

            <button
              type="submit"
              className="w-full text-white p-2 rounded-md transition flex justify-center items-center bg-pink-cta mt-8"
            >
              Register →
            </button>
          </form>

          <p className="text-center mt-4">
            Already have an account?{" "}
            <Link to="/login" className="text-pink-cta font-semibold">
              Login
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Register;
