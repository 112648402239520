import { LogoutOutlined } from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./logo";

const Sidebar = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("userId");
    navigate("/login");
  };

  return (
    <div className="h-screen sidebar flex flex-col justify-between">
      <Logo />
      <div>
        <button
          onClick={handleLogout}
          className="ml-4 mb-4 px-6 py-2 bg-pink-light-300 rounded-lg text-purple-700"
        >
          Logout <LogoutOutlined />
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
