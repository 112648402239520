import React from "react";

const WelcomeTextContainer = () => {
  return (
    <div className="chatContainerWelcomeText">
      <div className="text-2xl chatConntainerWHeading">Welcome, Taxmann</div>
      <div className="m-3 chatConntainerWText text-center">
        <div className="mb-1">
          Use Criminal Law Bot for your Criminal Law related questions.
        </div>
        <div className="mb-1">
          The more clear your query is, the better my response will be.
        </div>
      </div>
    </div>
  );
};

export default WelcomeTextContainer;
