import React from "react";

const Logo = () => {
  return (
    <div className="p-4 pl-8 logoContainer">
      <img
        className="h-18 w-60 taxmannLogo"
        alt="taxmannLogo"
        src="https://d3lzbbhyvqc4k.cloudfront.net/images-webp/main-logo.webp"
      />
    </div>
  );
};

export default Logo;
