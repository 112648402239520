import {
  CopyOutlined,
  DownOutlined,
  EditOutlined,
  FieldTimeOutlined,
  LoadingOutlined,
  UpOutlined,
} from "@ant-design/icons";
import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import copy from "copy-to-clipboard";
import axios from "axios";

const ChatBox = ({ chatId, chat, isLoading, setInput, isAnimating }) => {
  const {
    prompt,
    responseId,
    analysedQuery,
    agentReason,
    cotReason,
    result,
    pricing,
    timeTaken,
  } = chat;
  const [message, setMessage] = useState({
    prompt: prompt,
    analysedQueryRes: "",
    agentReasonRes: "",
    chainOfThoughtRes: "",
    resultRes: "",
  });
  const [answerIndex, setAnswerIndex] = useState(0);
  const [analysedQueryIndex, setAnalysedQueryIndex] = useState(0);
  const [agentReasonIndex, setAgentReasonIndex] = useState(0);
  const [chainOfThoughtsIndex, setChainOfThoughtsIndex] = useState(0);
  const [rating, setRating] = useState(0);
  const [showChainOfThoughts, setShowChainOfThoughts] = useState(true);
  const [showAgentReason, setShowAgentReason] = useState(true);

  const [agentReasonDisplayed, setAgentReasonDisplayed] = useState(false);
  const [cotReasonDisplayed, setCotReasonDisplayed] = useState(false);

  useEffect(() => {
    // if (!isAnimating) {
    //   setChainOfThoughts(cotReason);
    //   setAnswer(result);
    //   return;
    // }
    const splittedAnalysedQuery = analysedQuery.split("");
    if (splittedAnalysedQuery[analysedQueryIndex]) {
      setTimeout(() => {
        setMessage((prev) => ({
          ...prev,
          analysedQueryRes: splittedAnalysedQuery
            .slice(0, analysedQueryIndex + 1)
            .join(""),
        }));
        setAnalysedQueryIndex(analysedQueryIndex + 1);
      }, 5);
    } else if (
      analysedQueryIndex >= analysedQuery.length &&
      message.agentReasonRes.length < agentReason.length
    ) {
      const splittedAgentReason = agentReason.split("");
      if (splittedAgentReason[agentReasonIndex]) {
        setTimeout(() => {
          setMessage((prev) => ({
            ...prev,
            agentReasonRes: splittedAgentReason
              .slice(0, agentReasonIndex + 1)
              .join(""),
          }));
          setAgentReasonIndex(agentReasonIndex + 1);
        }, 5);
      }
      if (cotReason.length > 0) setChainOfThoughtsIndex(0);
    } else if (
      agentReasonIndex >= agentReason.length &&
      message.chainOfThoughtRes.length < cotReason.length
    ) {
      const splittedChainOfThoughts = cotReason.split("");
      if (splittedChainOfThoughts[chainOfThoughtsIndex]) {
        setTimeout(() => {
          setMessage((prev) => ({
            ...prev,
            chainOfThoughtRes: splittedChainOfThoughts
              .slice(0, chainOfThoughtsIndex + 1)
              .join(""),
          }));
          setChainOfThoughtsIndex(chainOfThoughtsIndex + 1);
        }, 5);
      }
    }
    if (
      // chainOfThoughtsIndex >= cotReason.length &&
      message.resultRes.length < result.length
    ) {
      const splittedResult = result.split("");
      if (splittedResult[answerIndex]) {
        setTimeout(() => {
          setMessage((prev) => ({
            ...prev,
            resultRes: splittedResult.slice(0, answerIndex + 1).join(""),
          }));
          setAnswerIndex(answerIndex + 1);
        }, 5);
      }
    }
  }, [
    analysedQueryIndex,
    agentReasonIndex,
    chainOfThoughtsIndex,
    answerIndex,
    analysedQuery,
    agentReason,
    cotReason,
    result,
    isAnimating,
  ]);

  useEffect(() => {
    async function fetchRatingResponse() {
      try {
        const ratingResponse = await axios.post(
          "https://ai.taxmann.com/api/rating_for_response",
          { recordid: responseId, rating: rating },
          { headers: { "Content-Type": "application/json" } }
        );
        if (ratingResponse?.status === 200) {
          toast.success("Response rated successfully!");
        } else {
          throw new Error("Unexpected response status");
        }
      } catch (error) {
        console.error("Error submitting rating:", error);
        toast.error("Failed to submit rating. Please try again.");
      }
    }
    if (rating > 0) {
      fetchRatingResponse();
    }
  }, [rating]);

  useEffect(() => {
    if (message.chainOfThoughtRes.length > 0 && !agentReasonDisplayed) {
      setShowAgentReason(false);
      setAgentReasonDisplayed(true);
    }
  }, [message.chainOfThoughtRes, agentReasonDisplayed]);

  useEffect(() => {
    if (message.resultRes.length > 0 && !cotReasonDisplayed) {
      setShowChainOfThoughts(false);
      setCotReasonDisplayed(true);
    }
  }, [message.resultRes, cotReasonDisplayed]);

  return (
    <div className="flex flex-col gap-2 chatBoxContainer">
      <div className="flex self-end items-end">
        <div onClick={() => setInput(prompt)} className="editBtn">
          <EditOutlined />
          <span>Edit</span>
        </div>
        <div className="p-4 px-8 promptContainer">
          <p className="w-fit">{prompt}</p>
        </div>
      </div>
      {isLoading && (
        <div className="w-fit pl-8 flex justify-center items-center gap-2">
          <LoadingOutlined />
          <div>Thinking...</div>
        </div>
      )}
      {message.analysedQueryRes && message.analysedQueryRes.length > 0 && (
        <div className="w-full ml-8 opacity-70 text-base leading-7 flex justify-between flex-wrap pr-8">
          <div>
            <span className="font-bold">Analysed query</span> :{" "}
            {message.analysedQueryRes}
          </div>
          {timeTaken.queryAnalyserApi > 0 && (
            <div className="text-sm bg-gray-200 px-2 py-1 rounded-full ">
              <FieldTimeOutlined className="text-purple-700 text-base" />={" "}
              {timeTaken.queryAnalyserApi} seconds
            </div>
          )}
        </div>
      )}
      {message.agentReasonRes && message.agentReasonRes.length > 0 && (
        <div className="w-full ml-8 opacity-70 text-sm leading-7 pr-8">
          <div className="flex justify-between items-center">
            <button
              onClick={() => setShowAgentReason((prev) => !prev)}
              className="mb-2 py-0 px-2 rounded-md chainOfThoughtsBtn"
            >
              Agent Reason{" "}
              <span className="ml-1">
                {showAgentReason ? (
                  <UpOutlined className="text-xs" />
                ) : (
                  <DownOutlined className="text-xs" />
                )}
              </span>
            </button>
            {timeTaken.queryDecomposerApi > 0 && (
              <div className="text-sm bg-gray-200 px-2 py-1 rounded-full ">
                <FieldTimeOutlined className="text-purple-700 text-base" />={" "}
                {timeTaken.queryDecomposerApi} seconds
              </div>
            )}
          </div>
          {showAgentReason && (
            <div className="pl-2 border-l-2 max-h-32 overflow-y-auto overflow-hidden">
              {message.agentReasonRes?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      {message.chainOfThoughtRes && message.chainOfThoughtRes.length > 0 && (
        <div className="w-full ml-8 opacity-70 text-sm leading-7 pr-8">
          <div className="flex justify-between items-center">
            <button
              onClick={() => setShowChainOfThoughts((prev) => !prev)}
              className="mb-2 py-0 px-2 rounded-md chainOfThoughtsBtn"
            >
              Chain of Thoughts{" "}
              <span className="ml-1">
                {showChainOfThoughts ? (
                  <UpOutlined className="text-xs" />
                ) : (
                  <DownOutlined className="text-xs" />
                )}
              </span>
            </button>
            {timeTaken.cotApi > 0 && (
              <div className="text-sm bg-gray-200 px-2 py-1 rounded-full ">
                <FieldTimeOutlined className="text-purple-700 text-base" />={" "}
                {timeTaken.cotApi} seconds
              </div>
            )}
          </div>
          {showChainOfThoughts && (
            <div className="pl-2 border-l-2 h-72 max-h-72 overflow-y-auto overflow-hidden">
              {message.chainOfThoughtRes?.split("\n").map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
      )}
      <div className="w-full ml-8 leading-7 flex flex-col pr-8">
        {timeTaken.criminalRagApi > 0 && (
          <div className="text-sm bg-gray-200 px-2 py-1 rounded-full self-end">
            <FieldTimeOutlined className="text-purple-700 text-base" />={" "}
            {timeTaken.criminalRagApi} seconds
          </div>
        )}
        <div>
          {message.resultRes?.split("\n").map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className="flex gap-2 pl-8 justify-between">
        <div className="flex">
          <CopyOutlined
            title="Copy to clipboard"
            onClick={() => {
              copy(message.resultRes);
              // navigator.clipboard.writeText(answer);
              toast.success("Copied to clipboard!");
            }}
            className="cursor-pointer text-xl"
          />
          <Rating
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            name="half-rating"
            precision={0.5}
          />
        </div>
        {message.resultRes && (
          <div className="mr-4 font-semibold">Usage:{pricing.toFixed(2)}</div>
        )}
      </div>
    </div>
  );
};

export default ChatBox;
